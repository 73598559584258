<template>
  <div class="ref-top ai-center jc-center">
    <div class="popup flex-col gap-20">
      <div class="jc-between">
        <div class="ai-center gap-10">
          <VIcon :src="require('@/assets/icons/ref-top.svg')" />
          <span class="title">Referente topografico</span>
        </div>
        <VButton class="close" :icon="require('@/assets/icons/cross.svg')" @click="close" />
      </div>
      <div class="grid grid-2-col gap-30">
        <div class="grid grid-2-col gap-5" v-if="!loading">
          <VInput class="grid-col-span medium solid" :icon="require('@/assets/icons/search.svg')" :iconSize="15"
            type="text" v-model="searchQuery" />
          <DropDown :options="regionsDropdown" :selected="currentRegion?.id" @select="selectRegion" label="Regioni" />
          <DropDown :options="insulaeDropdown" :selected="currentInsula?.id" @select="selectInsula" label="Insulae" />
          <div class="ref-top-list grid-col-span flex-col gap-5">
            <template v-for="(unity, i) in currentInsula?.children ? currentInsula.children : []" :key="i">
              <TheAccordion :index="i" :item="unity" :autoHeight="true" class="grid-col-span sub" @opened="selectUnity"
                :autoScroll="true" :isOpen="currentUnityIndex == i">
                <TheAccordion v-for="(enviroment, j) in currentUnityIndex == i ? unity.children : []" :index="j"
                  :item="enviroment" :key="j" :autoScroll="true" @opened="selectEnvironment"
                  :isOpen="currentEnvironmentIndex == j">
                  <div class="options-box flex-col" v-if="currentEnvironmentIndex == j">
                    <RadioButton v-model="topElId" :value="el.id" :label="el.frontend_title"
                      v-for="(el, i) in currentEnvironment.topographic_element4s" :key="i" />
                  </div>
                </TheAccordion>
              </TheAccordion>
            </template>
          </div>
        </div>
        <VLottie class="loading" v-if="loading" ref="animation" :src="require('@/assets/icons/loading.json')"
          :autoplay="true" :loop="true" />
        <div class="flex-col">
          <VMap v-if="regions.length > 0" :polygons="polygons" :geolocate="!startingPosition" :customLayers="MAP_LAYERS"
            :leafletConfig="{ minZoom: 16 }" :zoomDuration="1" :fitBounds="true" @location="filterByLocation"
            :orientation="true" :center="MAP_CENTER" />
          <div class="jc-end act-buttons gap-15">
            <VButton class="medium rounded" label="Cancella" @click="close" />
            <VButton class="medium rounded" label="Salva" @click="save" :disabled="!topElId" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DropDown from './DropDown.vue';
import TheAccordion from './TheAccordion.vue';
import RadioButton from './RadioButton.vue';
import { onUnmounted, ref } from 'vue'
import { useUXStore } from '@/stores/ux';

import { getNearestTopographicElement4 } from '@/services/topographicServices';
import { MAP_LAYERS, POLYGON_STYLES, MAP_CENTER, geojsonToTurf } from '@/utils/polygon';
import { customArabicSort, customRomanSort } from '@/utils/utils';
import { getEnvironments, getInsulae, getRegions, getTopographicElements4, getUnities } from '@/services/cacheServices';

export default {
  name: 'RefTopPopUp',
  components: {
    DropDown,
    TheAccordion,
    RadioButton
  },
  props: {
    startingPosition: {
      type: Object,
      default: null,
    },
  },
  watch: {
    topElId(id) {
      this.selectTopographicElement(id)
    }
  },
  computed: {
    regionsDropdown() {
      return this.regions
        ?.slice()
        .sort(customRomanSort)
        .map(poly => {
          return {
            label: poly.name,
            value: poly.id,
          }
        })
    },
    insulaeDropdown() {
      return this.currentRegion?.children
        ?.slice().sort(customArabicSort)
        .map(insula => {
          return {
            label: insula.name,
            value: insula.id,
          };
        });
    },
    currentUnityIndex() {
      return this.currentInsula?.children?.findIndex(unity => unity.id === this.currentUnity?.id)
    },
    currentEnvironmentIndex() {
      return this.currentUnity?.children?.findIndex(environment => environment.id === this.currentEnvironment?.id)
    },
    polygons() {
      console.log("currentPolygons", this.currentPolygons)
      return this.currentPolygons.map(p => { return { ...p, polygon: p.transformedPolygon ?? p.transformed_polygon } })
    }
  },
  mounted() {
    console.log(this)
    this.uxstore.showOverlay = true
    getRegions().then(regions => {
      this.regions = regions
      this.currentPolygons = this.regions.map(region => {
        return {
          ...region,
          style: POLYGON_STYLES.foreground,
          onClick: async () => {
            await this.selectRegion({ value: region.id })
          }
        }
      })
      this.loading = false
    })
  },
  methods: {
    async selectRegion(region) {
      this.currentRegion = this.regions.find(r => r.id === region.value)

      // if no insulae, fetch them
      if (!this.currentRegion.children) {
        this.currentRegion.children = await getInsulae(this.currentRegion.id)
      }

      // set style for insulae
      this.currentRegion.children.forEach(insula => {
        insula.style = POLYGON_STYLES.foreground

        insula.onClick = async () => {
          this.selectInsula({ value: insula.id })
        }
      })

      // set current region style, invisible polygon
      this.currentRegion.style = POLYGON_STYLES.transparent
      this.currentRegion.focus = true
      this.currentRegion.notInteractive = true

      // set other regions style
      this.regions.filter(r => r.id !== this.currentRegion.id).forEach(region => {
        region.style = POLYGON_STYLES.background
      })

      this.currentPolygons = [
        ...this.currentRegion.children,
        this.currentRegion
      ]
      this.currentInsula = null
      this.currentUnity = null
      this.currentEnvironment = null
    },
    async selectInsula(insula) {
      console.log(this.currentRegion)
      this.currentInsula = this.currentRegion.children.find(i => i.id === insula.value)

      if (!this.currentInsula.children) {
        this.currentInsula.children = await getUnities(this.currentInsula.id)
      }

      // set style for unity
      this.currentInsula.children.forEach(unity => {
        unity.style = POLYGON_STYLES.foreground

        unity.onClick = async () => {
          await this.selectUnity(unity.id, 'id')
        }
      })

      // set current insula style, invisible polygon
      this.currentInsula.style = POLYGON_STYLES.transparent
      this.currentInsula.focus = true
      this.currentInsula.notInteractive = true

      // set other insulae style
      this.currentRegion.children.filter(i => i.id !== this.currentInsula.id).forEach(insula => {
        insula.style = POLYGON_STYLES.background
      })

      this.currentPolygons = [
        ...this.currentInsula.children,
        this.currentInsula,
        //...this.currentRegion.insula.filter(i => i.topographic_element_id !== this.currentInsula.topographic_element_id), // disabled for now
      ]

      this.currentUnity = null
      this.currentEnvironment = null
    },
    async selectUnity(unityId, parameterType) {
      let currentUnityIndex = null
      if (parameterType == 'id') {
        currentUnityIndex = this.currentInsula.children.findIndex(unity => unity.id === unityId)
      } else {
        currentUnityIndex = unityId
      }

      this.currentUnity = { ...this.currentInsula.children[currentUnityIndex] }


      if (!this.currentUnity.children) {
        this.currentUnity.children = await getEnvironments(this.currentUnity.id)

        // search for current unity in currentInsula and set environments
        this.currentInsula.children = this.currentInsula.children.map(unity => {
          if (unity.id === this.currentUnity.id) {
            unity.children = this.currentUnity.children
          }
          return unity
        })
      }

      // set style for environment
      this.currentUnity.children = this.currentUnity.children.map(environment => ({
        ...environment,
        style: POLYGON_STYLES.foreground,
        onClick: () => {
          this.selectEnvironment(environment.id, 'id')
        }
      }));

      // set current unity style, invisible polygon
      this.currentUnity = {
        ...this.currentUnity,
        style: POLYGON_STYLES.transparent,
        focus: true,
        notInteractive: true
      }

      // set other unity style
      this.currentInsula.children = this.currentInsula.children.map(unity =>
        unity.id !== this.currentUnity.id
          ? { ...unity, style: POLYGON_STYLES.background }
          : unity
      );

      this.currentPolygons = [
        ...this.currentUnity.children,
        this.currentUnity,
        //...this.currentInsula.unity.filter(unity => unity.topographic_element_id !== this.currentUnity.topographic_element_id), // disabled for now
      ]
      this.currentEnvironment = null
    },
    async selectEnvironment(id, parameterType) {
      let selectedEnvironmentId = null
      let selectedEnvironmentIndex = null
      if (parameterType == 'id') {
        selectedEnvironmentId = id
        selectedEnvironmentIndex = this.currentUnity.children.findIndex(environment => environment.id == selectedEnvironmentId);
      } else {
        selectedEnvironmentIndex = id
        selectedEnvironmentId = this.currentUnity.children[selectedEnvironmentIndex].id
      }

      if (this.currentEnvironment?.id == selectedEnvironmentId) {
        return
      }

      this.currentEnvironment = { ...this.currentUnity.children[selectedEnvironmentIndex] }

      if (!this.currentEnvironment.topographic_element4s) {
        this.currentEnvironment.topographic_element4s = await getTopographicElements4(this.currentEnvironment.id)
      }

      // update style and onClick for topographic elements
      this.currentEnvironment.topographic_element4s = this.currentEnvironment.topographic_element4s.map(topographicElement4 => {
        return {
          ...topographicElement4, style: {
            ...POLYGON_STYLES.foreground,
            weight: 7,
          },
          onClick: () => {
            this.selectTopographicElement(topographicElement4.id)
          }
        }
      })

      // set current environment style, invisible polygon
      this.currentEnvironment.style = POLYGON_STYLES.transparent

      // set other environments style
      this.currentUnity.children.filter(environment => environment.id !== this.currentEnvironment.id).forEach(environment => {
        environment.style = POLYGON_STYLES.background
      })

      // create a copy of topographic_element4s array
      let topographicElement4sCopy = [...this.currentEnvironment.topographic_element4s]
      console.log("topographicElement4sCopy", topographicElement4sCopy)

      // assign order value depending on type. 1 for 'Superficie orizzontale', 100 for 'Superficie verticale', else 50
      topographicElement4sCopy.forEach(element => {
        if (element.type.name === 'Superficie orizzontale') {
          element.order = 1
        } else if (element.type.name === 'Superficie verticale') {
          element.order = 100
        } else {
          element.order = 50
        }
      })

      this.currentPolygons = [
        this.currentEnvironment,
        ...topographicElement4sCopy.sort((a, b) => a.order - b.order)
      ]
    },

    selectTopographicElement(topographicElementId) {
      if (typeof topographicElementId === 'object') {
        this.selectedTopographicElementId = topographicElementId.target.value
      } else {
        this.selectedTopographicElementId = topographicElementId
      }

      // if topElId is not equal to topographicElementId, set topElId to topographicElementId
      if (this.topElId !== this.selectedTopographicElementId) {
        this.topElId = this.selectedTopographicElementId
      }

      let topographicElement4 = this.currentEnvironment.topographic_element4s.find(element => element.id == this.selectedTopographicElementId)

      this.currentEnvironment.topographic_element4s.forEach(element => {
        element.style = POLYGON_STYLES.foreground
      })
      this.currentEnvironment.style = POLYGON_STYLES.transparent
      this.currentEnvironment.focus = true

      topographicElement4.style = POLYGON_STYLES.selected

      this.currentUnity.children.forEach(environment => {
        environment.style = POLYGON_STYLES.background
      })

      this.currentPolygons = [
        topographicElement4,
        this.currentEnvironment,
        //...this.currentUnity.environments.filter(environment => environment.topographic_element_id !== this.currentEnvironment.topographic_element_id), // disabled for now
      ]
    },
    async filterByLocation(position) {
      this.currentPosition = position
      await this.calculatePolygons()
    },
    async calculatePolygons() {
      let result = []

      let availableUserPosition = this.startingPosition ? this.startingPosition : this.currentPosition

      const startingPosition = availableUserPosition ? geojsonToTurf(this.currentPosition) : null
      let nearestTopographicElement4 = null;

      if (availableUserPosition) {
        try {
          nearestTopographicElement4 = (await getNearestTopographicElement4(startingPosition.geometry.coordinates[0], startingPosition.geometry.coordinates[1])).data
        } catch (error) {
          console.error("Error getting nearest topographic element", error)
        }
      }

      if (nearestTopographicElement4) {
        console.log("nearest", nearestTopographicElement4)
        await this.selectRegion({ value: nearestTopographicElement4.parent.parent.parent.parent.id })
        await this.selectInsula({ value: nearestTopographicElement4.parent.parent.parent.id })
        await this.selectUnity(nearestTopographicElement4.parent.parent.id, 'id')
      } else {
        // if no position is available, return all the polygons
        result = this.polygons
        this.currentPolygons = result
      }

    }
  },
  emits: ['close', 'save'],
  setup(props, { emit }) {
    const loading = ref(true)
    const searchQuery = ref('')
    const currentPosition = ref(null)
    const currentRegion = ref(null)
    const currentInsula = ref(null)
    const currentUnity = ref(null)
    const currentEnvironment = ref(null)
    const selectedTopographicElementId = ref(null)
    const uxstore = useUXStore()
    const topElId = ref()

    // different topographic elements
    const regions = ref([])

    const currentPolygons = ref([])

    const close = () => {
      emit('close')
    }

    const save = () => {
      let topographicElement = currentEnvironment.value.topographic_element4s.find(element => element.id == topElId.value)
      console.log(topographicElement)
      topographicElement.environment = currentUnity.value.children.find(environment => environment.id == topographicElement.topographic_element_id)
      topographicElement.environment.unity = currentInsula.value.children.find(unity => unity.id == topographicElement.environment.parent_id)
      topographicElement.environment.unity.insula = currentRegion.value.children.find(insula => insula.id == topographicElement.environment.parent.parent_id)
      topographicElement.environment.unity.insula.region = regions.value.find(region => region.id == topographicElement.environment.parent.parent.parent_id)

      // simplify the object setting to null some attributes
      topographicElement.environment.topographic_element4s = null
      topographicElement.environment.unity.environments = null
      topographicElement.environment.unity.insula.unity = null
      topographicElement.environment.unity.insula.region.insula = null

      emit('save', topographicElement)
    }

    onUnmounted(() => {
      uxstore.showOverlay = false

      currentPosition.value = null;
      currentRegion.value = null;
      currentInsula.value = null;
      currentUnity.value = null;
      currentEnvironment.value = null;
      selectedTopographicElementId.value = null;
      regions.value = [];
      currentPolygons.value = [];
    })

    return {
      close, save, loading, searchQuery, props, selectedTopographicElementId, uxstore,
      currentPosition, regions, currentPolygons, MAP_LAYERS, MAP_CENTER,
      currentRegion, currentInsula, currentUnity, currentEnvironment, topElId
    }
  }
}
</script>

<style>
.ref-top {
  height: 100%;
  width: 100%;
  position: absolute;
}

.ref-top .popup {
  position: relative;
  background-color: var(--primary-color);
  height: 80%;
  width: 80%;
  padding: 30px;
  z-index: 6;
}

.ref-top .popup>div:last-child {
  height: 100%;
  overflow: hidden;
}

.ref-top .popup>div:last-child>div:first-child {
  grid-template-rows: max-content max-content;
  overflow: hidden;
}

.ref-top .popup .act-buttons {
  margin: 30px 0 10px 0;
}

.ref-top .popup span.title {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.875rem;
  letter-spacing: 0.035rem;
}

.ref-top .popup .act-buttons .v-button {
  background-color: var(--base-dark-color);
  color: var(--primary-color);
  justify-content: center;
  width: 100px;
}

.ref-top-list {
  overflow-y: scroll;
  max-height: 50dvh;
}

.accordion.sub .panel {
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px
}

.accordion:not(.sub) :is(.title, .panel) {
  background-color: #D9D9D9;
  display: flex;
}

.accordion:not(.sub) .panel {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.options-box {
  margin: 10px;
  gap: 7px;
}

.options-box .radiobutton {
  height: 20px;
}

.ref-top .popup .dropdown :is(.selected, .box) {
  width: 100%;
}

.ref-top-option {
  text-align: left;
  display: grid;
  grid-template-columns: 1em 1fr;
}
</style>