<template>
  <div v-if="options.length" class="dropdown" tabindex="0" @blur="open = false">
    <div class="selected" :class="{ open: open }" @click="open = !open">
      <div class="box">
        <input v-if="name" type="hidden" :name="name" :value="selectedOption?.value" />
        <span v-if="selectedOption?.color" class="square-color" :style="style"></span>
        <!-- <img v-if="selected.icon" :src="require('../assets/icons/' + selected.icon)"> -->
        <VIcon v-if="selectedOption?.icon" :src="require('@/assets/icons/' + selectedOption.icon)" />
        <p>{{ selectedOption ? selectedOption?.label : label }}</p>
      </div>
      <VIcon :src="require('@/assets/icons/simple-arrow.svg')" class="arrow" :class="{ arrowOpen: open }" />
    </div>
    <Transition name="dropdown">
      <div class="category" v-if="open">
        <div class="box" v-for="(option, i) in filteredOptions" :key="i" @click="select(option)">
          <span v-if="option.color" class="square-color" :style="{ background: '#' + option.color }"></span>
          <VIcon v-if="option.icon" :src="require('/src/assets/icons/' + option.icon)" />
          <p>{{ option?.label }}</p>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import { ref, computed } from "vue";

export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: [Number, String],
      default: undefined  
    },
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
  },
  emits: ['select'],
  setup(props, { emit }) {
    const open = ref(false);

    const select = (option) => {
      open.value = false;
      emit('select', option);
    }

    const style = computed(() => {
      return selectedOption.value.color ? {
        background: '#' + this.selectedOption.color
      } : {};
    })
    const selectedOption = computed(() => {
      return props.options.find(o => { return o.value === props.selected })
    })

    const filteredOptions = computed(() => {
      if (!selectedOption.value) return props.options;
      return props.options.filter(o => { return o.label !== selectedOption.value.label })
    })

    return { open, select, style, selectedOption, filteredOptions };
  },
};
</script>

<style scoped>
div {
  align-items: center;
}

p {
  font-size: 12px;
  font-weight: 500;
}

.box div {
  margin-right: 8px;
}

.dropdown {
  display: block;
  position: relative;
  text-align: left;
  outline: none;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.05em;
}

.dropdown .selected {
  align-items: center;
  position: relative;
  justify-content: space-between;
  height: 45px;
  width: 200px;
  background: var(--base-mid-color);
  cursor: pointer;
  user-select: none;
  z-index: 2;
  /*Safari fix z-index bug*/
  -webkit-transform: translate3d(0, 0, 0);
  --icon-color: var(--primary-color)
}

.dropdown .category::-webkit-scrollbar {
  width: 10px;
}

.dropdown .category::-webkit-scrollbar-thumb {
  border-left: 5px solid #ffffff;
}
/* .dropdown .category::-webkit-scrollbar-track {
  width: 5px;
} */


.dropdown .category {
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 3;
  transform-origin: top center;
  gap: 4px;
  margin-top: 4px;
  max-height: 200px;
  overflow-y: scroll;
}

.dropdown .category .box {
  height: 45px;
  width: 200px;
  background: var(--base-mid-color);
  /* margin: 5px auto; */
  cursor: pointer;
  user-select: none;
  transition: .5s;
  z-index: 1;
}

.box {
  padding: 15px;
}

.arrow {
  transition: all .4s ease;
  padding: 0 17px;
}

.arrowOpen {
  transition-delay: .1s;
  transform: rotateX(180deg);
}

.dropdown .category .box:hover {
  background-color: var(--base-dark-color);
  transition: .2s;
}


.dropdown-enter-active,
.dropdown-leave-active {
  transition: all .3s;
}

.dropdown-enter-from,
.dropdown-leave-to {
  opacity: 0;
  transform: scaleY(0);
  /* transform-origin: top center; */
}
</style>