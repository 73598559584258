import { ref, toRaw } from "vue"
import { axiosInstanceWithAuth, readFileAsBlob, blobToBase64 } from '@/utils/utils'
import { openDB } from 'idb'
import { useSettingsStore } from '@/stores/settings'

export const punctualMonitoringDBParams = {
  version: 6,
  upgrade(db) {
    if (!db.objectStoreNames.contains("punctual-monitoring")) {
      db.createObjectStore("punctual-monitoring", { keyPath: "id", autoIncrement: true });
    }

    if (!db.objectStoreNames.contains("photoBlobs")) {
      db.createObjectStore("photoBlobs", { keyPath: "id", autoIncrement: true });
    }

    if (!db.objectStoreNames.contains("drawingBlobs")) {
      db.createObjectStore("drawingBlobs", { keyPath: "id", autoIncrement: true });
    }
  },
}


const useMonitoring = (type) => {
  const settingsStore = useSettingsStore()
  const error = ref(null)
  const headers = { 'Content-Type': 'multipart/form-data' }

  async function saveToIDB(type, data) {
    // preprocess data passing everything through toRaw to remove reactivity
    const rawData = toRaw(data)

    rawData.degradations = rawData.degradations.map(degradation => {
      if (degradation?.files) {
        degradation.files = toRaw(degradation.files)
        degradation.drawings = toRaw(degradation.drawings)
      }

      if (degradation.deletedFiles) {
        degradation.deletedFiles = toRaw(degradation.deletedFiles)
      }

      if (degradation.gravity) {
        degradation.gravity = toRaw(degradation.gravity)
      }

      if (degradation.degradation_type) {
        degradation.degradation_type = toRaw(degradation.degradation_type)
      }

      return toRaw(degradation)
    })

    let db = await openDB(type + '-page-local-sync', punctualMonitoringDBParams.version, punctualMonitoringDBParams)

    await processDegradations(db, rawData.degradations)

    const lastMonitoring = await db.get(type, 'pageLocalSyncRecord')

    if (lastMonitoring) {
      await db.put(type, { data: rawData, id: 'pageLocalSyncRecord' })
    } else {
      await db.add(type, { id: 'pageLocalSyncRecord', data: rawData })
    }

    db.close()
  }

  async function processDegradations(db, degradations) {
    for (const degradation of degradations) {
      if (!degradation?.files || degradation.files?.length === 0) continue
      degradation.files = await processFiles(db, "photoBlobs", degradation.files || [])
      degradation.drawings = await processFiles(db, "drawingBlobs", degradation.drawings || [])
    }
  }

  async function processFiles(db, storeName, files) {
    return Promise.all(
      files.map(async (file) => {
        if (file instanceof Blob) {
          const blob = await readFileAsBlob(file);
          const blobId = await db.add(storeName, { blob });
          return blobId;
        } else {
          return toRaw(file)
        }
      })
    );
  }

  const addMonitoring = async (data, type, pageLocalSync = false) => {
    // if there is no internet connection, the monitoring will be saved in the indexedDB and will be sent to the server when the network will be reachable
    error.value = null

    if (pageLocalSync) {
      // save the form data in the indexedDB with a autoincrement id. The id will be used to identify the monitoring in the indexedDB and to send it to the server when the network will be reachable
      try {
        await saveToIDB(type, data)
      } catch (error) {
        //console.error('Error writing to indexedDB', error);
      }
    } else if (settingsStore.settings.offlineMode && window.ReactNativeWebView) {
      // convert blobs inside data to base64
      for (const degradation of data.degradations) {
        const blobIds = await Promise.all(
          degradation.files.map(async (file) => {
            const blob = await readFileAsBlob(file);
            const base64 = await blobToBase64(blob);
            return base64;
          })
        );
        degradation.files = blobIds;

        if (degradation.drawings) {
          const blobIds2 = await Promise.all(
            degradation.drawings.map(async (file) => {
              const blob = await readFileAsBlob(file);
              const base64 = await blobToBase64(blob);
              return base64;
            })
          );
          degradation.drawings = blobIds2;
        }
        console.log(degradation.drawings)
        console.log(degradation.files)
      }

      window.ReactNativeWebView.postMessage(JSON.stringify(data))

      clearDataToSync(type)
    } else {
      try {
        // if there is data.data, use it to send the form data to the server
        if (data.data) {
          data = data.data
        }

        let res = null;
        // if the id is set, it means that the monitoring is already saved in the server and we need to update it
        if (data.id) {
          res = await axiosInstanceWithAuth.post(`/api/${type}/` + data.id, {
            _method: 'PUT',
            ...data
          }, { headers: headers })
        } else {
          res = await axiosInstanceWithAuth.post(`/api/${type}`, data, { headers: headers })
        }

        if (res.status === 200 || res.status === 201) {
          clearDataToSync(type)
          return res.data.id
        } else {
          throw new Error('Error while saving the monitoring')
        }
      } catch (err) {
        console.log(err.response)
        let message = err.response?.data?.message || 'Error while saving the monitoring'
        if (err.response?.status === 422) {
          error.value = err.response.data.errors
          console.log(error.value)
        }

        throw new Error(message)
      }
    }
  }

  const getDataToSync = async () => {
    let db = await openDB(type + '-page-local-sync', punctualMonitoringDBParams.version, punctualMonitoringDBParams)
    let data = await db.get(type, 'pageLocalSyncRecord')
    db.close()
    return data ? data.data : null
  }

  const clearDataToSync = async (type) => {
    try {
      let db = await openDB(type + '-page-local-sync', punctualMonitoringDBParams.version, punctualMonitoringDBParams);

      // check if the pageLocalSyncRecord exists and delete it
      if (await db.get(type, 'pageLocalSyncRecord')) {
        await db.delete(type, 'pageLocalSyncRecord');
      }

      await db.clear('photoBlobs');

      await db.clear('drawingBlobs');
      db.close();
    } catch (error) {
      console.error('Error clearing data to sync', error);
    }
  };


  return { error, addMonitoring, getDataToSync, clearDataToSync }
}

export default useMonitoring