import { axiosInstanceWithAuth } from "@/utils/utils";
import { openDB } from "idb";

export async function createTablesDB() {
    return openDB("tables", 2, {
        upgrade(db) {
            if (!db.objectStoreNames.contains("projects")) {
                db.createObjectStore("projects", { keyPath: "id" });
            }
            if (!db.objectStoreNames.contains("degradationTypes")) {
                db.createObjectStore("degradationTypes", { keyPath: "id" });
            }
        }
    });
}

export async function createPageCachingDB() {
    return openDB("page-caching", 2, {
        upgrade(db) {
            if (!db.objectStoreNames.contains("pages")) {
                db.createObjectStore("pages", { keyPath: "url" });
            }
        }
    });
}

export async function getTopographicElements() {
    let topographicElementsDB = await openDB("topographic-elements", 2, {
        upgrade(db) {
            if (!db.objectStoreNames.contains("regions")) {
                const regionsStore = db.createObjectStore("regions", { keyPath: "topographic_element_id" });
                regionsStore.createIndex("by_insula", "insula.topographic_element_id", { unique: false });
            }
        }
    });

    return topographicElementsDB;
}

export async function getRegions() {
    let topographicElementsDB = await getTopographicElements();
    let result = null;

    if (topographicElementsDB.objectStoreNames.contains("regions") && await topographicElementsDB.count("regions") > 0) {
        result = await topographicElementsDB.getAll("regions");
    } else {
        let response = await axiosInstanceWithAuth.get('/api/bff/map/regions?noParent=true&noIndices=true')
        result = response.data.data
    }

    topographicElementsDB.close();

    return result;
}

export async function getInsulae(regionId) {
    let topographicElementsDB = await getTopographicElements();
    let result = null;

    if (topographicElementsDB.objectStoreNames.contains("regions") && await topographicElementsDB.count("regions") > 0) {
        let region = await topographicElementsDB.get("regions", regionId);

        if (region) {
            result = region.insula;
        }
    } else {
        let response = await axiosInstanceWithAuth.get(`/api/bff/map/regions/${regionId}?noIndices=true`)
        result = response.data.data
    }

    topographicElementsDB.close();

    return result;
}

export async function getUnities(insulaId) {
    let topographicElementsDB = await getTopographicElements();
    let result = null;

    if (topographicElementsDB.objectStoreNames.contains("regions") && await topographicElementsDB.count("regions") > 0) {
        let regions = await topographicElementsDB.getAll("regions");

        for (const region of regions) {
            for (const insula of region.insula) {
                if (insula.topographic_element_id === insulaId) {
                    result = insula.unity;
                    break;
                }
            }
        }
    } else {
        let response = await axiosInstanceWithAuth.get(`/api/bff/map/insulae/${insulaId}`)
        result = response.data.data
    }

    topographicElementsDB.close();

    // sort unities by name
    result.sort((a, b) => a.name.localeCompare(b.name));

    return result;
}

export async function getEnvironments(unityId) {
    let topographicElementsDB = await getTopographicElements();
    let result = null;

    if (topographicElementsDB.objectStoreNames.contains("regions") && await topographicElementsDB.count("regions") > 0) {
        let regions = await topographicElementsDB.getAll("regions");

        for (const region of regions) {
            for (const insula of region.insula) {
                for (const unity of insula.unity) {
                    if (unity.topographic_element_id === unityId) {
                        result = unity.environments;
                        break;
                    }
                }
            }
        }
    } else {
        let response = await axiosInstanceWithAuth.get(`/api/bff/map/unities/${unityId}?noIndices=true&noParent=true`)
        result = response.data.data
    }

    topographicElementsDB.close();

    // sort environments by name
    result.sort((a, b) => a.name.localeCompare(b.name));

    return result;
}

export async function getTopographicElements4(environmentId) {
    let topographicElementsDB = await getTopographicElements();
    let result = null;

    if (topographicElementsDB.objectStoreNames.contains("regions") && await topographicElementsDB.count("regions") > 0) {
        let regions = await topographicElementsDB.getAll("regions");

        for (const region of regions) {
            for (const insula of region.children) {
                for (const unity of insula.children) {
                    for (const environment of unity.children) {
                        if (environment.parent_id === environmentId) {
                            result = environment.topographic_element4s;
                            break;
                        }
                    }
                }
            }
        }
    } else {
        let response = await axiosInstanceWithAuth.get(`/api/bff/map/environments/${environmentId}?noIndices=true&noParent=true`)
        result = response.data.data
    }

    topographicElementsDB.close();

    // sort topographic elements by name
    result.sort((a, b) => a.name.localeCompare(b.name));

    return result;
}

export async function clearCache() {
    const dbNames = ['tables', 'topographic-elements'];

    for (const dbName of dbNames) {
        const db = await openDB(dbName);
        if (db.objectStoreNames.length > 0) {
            const tx = db.transaction(db.objectStoreNames, 'readwrite');
            for (const storeName of db.objectStoreNames) {
                tx.objectStore(storeName).clear();
            }
            await tx.done;
        }
    }
}

export async function clearServiceWorkerCache() {
    const keys = await caches.keys();

    for (const key of keys) {
        await caches.delete(key);
    }
}